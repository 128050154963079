/* eslint-disable no-param-reassign */
import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import type {ShopShare} from "@atg-shop-shared/types";

export type ShareReducerState = {
    shares: ShopShare[];
    loading: boolean;
    error: boolean;
};

const initialState = {
    shares: [],
    loading: false,
    error: false,
};

export type FetchSharesPayload = {
    shopId: number;
    page: number;
    limit: number;
};

export const shareSlice = createSlice({
    name: "shareNext",
    initialState,
    reducers: {
        fetchShares: (state: ShareReducerState, _: PayloadAction<FetchSharesPayload>) => {
            state.loading = true;
            state.error = false;
        },
        fetchSharesSuccess: (
            state: ShareReducerState,
            {payload: shares}: PayloadAction<ShopShare[]>,
        ) => {
            state.loading = false;
            state.error = false;
            state.shares = shares;
        },
        fetchSharesError: (state: ShareReducerState) => {
            state.loading = false;
            state.error = true;
            state.shares = [];
        },
        updateShare: (
            state: ShareReducerState,
            {payload}: PayloadAction<Partial<ShopShare>>,
        ) => {
            state.shares = state.shares.reduce<ShopShare[]>(
                (acc, share) =>
                    share.id === payload.id
                        ? [...acc, {...share, ...payload}]
                        : [...acc, share],
                [],
            );
        },
    },
});

// Reducer actions
export const {fetchShares, fetchSharesSuccess, fetchSharesError, updateShare} =
    shareSlice.actions;

export default shareSlice.reducer;
